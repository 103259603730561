//在官方的axios的基础上封装一个添加拦截器的axios
import axios from 'axios'
//配置默认的路由地址头
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : ''
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
//全局添加拦截器的作用是可以在每个api前面加上headers的token验证
axios.interceptors.request.use(config => {
  return config;
})
export default axios
